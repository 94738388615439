import type { ExtractFromAPI, v1 } from "@netgame/openapi";

import useAsyncFetch from "./useAsyncFetch";
import useIsGuest from "./useIsGuest";
import type { EventData } from "./useNotificationCenter";
import useSockets from "./useSockets";
import useSwitchMode from "./useSwitchMode";

export type PlayersActivityResponse = ExtractFromAPI<v1.paths, "/rest/players-activity/", "get">;
export type PlayersActivityArray = NonNullable<PlayersActivityResponse["data"]>;
export type PlayersActivity = PlayersActivityArray[number];

const MAX_SAVED_DATA_SIZE = 15;

const useLatestWinners = () => {
	const playersActivity = useState<Array<PlayersActivity>>("playersActivity", () => []);
	const { data: fetchedPlayersActivity } = useAsyncFetch({
		path: "/rest/players-activity/",
		method: "get",
		options: {
			cached: true,
			default: () => [],
			transform: (data) => data.data ?? []
		},
		fetchOptions: {
			query: {
				limit: 6
			},
			onResponse: ({ response }) => {
				playersActivity.value = response._data.data ?? [];
			}
		}
	});

	const { add, remove } = useSockets();
	const isGuest = useIsGuest();
	const { activeGameMode } = useSwitchMode();
	const recentWinners = useState<Map<number, PlayersActivity>>("recentWinners", () => new Map());

	add("addPlayerLastAction", ({ data }: EventData<PlayersActivity>) => {
		playersActivity.value = [data, ...playersActivity.value].slice(0, MAX_SAVED_DATA_SIZE);

		if (recentWinners.value.size > MAX_SAVED_DATA_SIZE) {
			recentWinners.value.clear();
		}

		if (isGuest.value && data.gameMode === "TournamentPoints") {
			recentWinners.value.set(data.data.id, data);
			return;
		}

		if (activeGameMode.value === "entries") {
			recentWinners.value.set(data.data.id, data);
		} else if (activeGameMode.value === "coins" && data.gameMode === "TournamentPoints") {
			recentWinners.value.set(data.data.id, data);
		}
	});

	onUnmounted(() => {
		remove("addPlayerLastAction");
	});

	return { recentWinners, playersActivity, fetchedPlayersActivity };
};

export default useLatestWinners;
